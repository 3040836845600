var menuOffset;
$(window).resize(function() {
    menuOffset = $('.anchor-nav-wrapper').outerHeight();
});

$(document).ready(function() {
    menuOffset = $('.anchor-nav-wrapper').outerHeight();

    $('.carousel').slick({
        slideToShow: 1,
        arrows: true,
        dots: true,
        autoplay: true,
        autoplaySpeed: 10000
    });

    $(document).on('click', '.anchor-nav a[href^="#"]', function (event) {
        event.preventDefault();
        $('html, body').animate({
            scrollTop: $($.attr(this, 'href')).offset().top - menuOffset
        }, 500);
    });

    $('[data-toggle-active]').click(function (){
        $('[data-toggle-active]').removeClass('active');
        $(this).addClass('active');

        var anchorNav = $(this).parents('.anchor-nav');
        var menuBtn = anchorNav.siblings('[data-trigger-mobile-menu]');
        if (menuBtn.is(":visible")) {
            anchorNav.slideToggle();
            menuBtn.toggleClass('opened');
        }
    });

    $('[data-trigger-mobile-menu]').click(function(){
        $(this).toggleClass('opened');
        $(this).siblings('.anchor-nav').slideToggle();
	});

});